/* MODAL SALE */

.myne-modal__modal-new-sale {
  width: 884px;
  height: 479px;
}
/* header */
.myne-modal__modal-new-sale .myne-modal__modal-edit-header {
  justify-content: space-around;
}
.myne-modal__modal-new-sale .myne-modal__modal-edit-header__left {
  margin-right: auto;
}

.myne-modal__modal-new-sale__header-filter-area {
  position: absolute;
  margin: 0 auto;
  width: 225px;
  height: 45px;
}

/* footer */
.myne-modal__modal-new-sale_bottom {
  width: 100%;
  display: flex;
  align-items: center;
  border-top: 1px solid #ebeff2;
  height: 69px;
  padding: 12px;
  padding-left: 24px;
}

.myne-modal__modal-new-sale_bottom--left {
  width: 346px;
  margin-bottom: 4px;
}

.myne-modal__modal-new-sale_bottom--center {
  margin-left: 24px;
}

.myne-area__select-date {
  display: flex;
  align-items: center;
  padding: 10px 32px;
  cursor: pointer;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 124px;
  position: relative;
}

.myne-area__select-date > div {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
}
.myne-area__select-date > div .ant-picker {
  width: 100%;
  padding: 0;
  height: 100%;
}
.myne-area__select-date > div .ant-picker-suffix {
  display: none;
}
.myne-area__select-date > div .ant-picker-clear {
  display: none;
}
.myne-area__select-date > div .ant-picker-input > input {
  cursor: pointer;
}

.myne-area__select-date:hover {
  background: var(--background_hover);
}

.myne-area__select-date > p {
  margin: 0;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  margin-left: 10px;
}

.myne-modal__modal-new-sale_bottom--right {
  margin-left: auto;
}

.myne-modal__modal-new-sale_bottom--right > button {
  width: 129px;
  height: 44px;
  background: rgba(0, 0, 0, 0.06);
  border-radius: 124px;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  outline: none;
  color: rgba(0, 0, 0, 0.36);
}

.myne-modal__modal-new-sale_bottom--right > button:hover {
  opacity: 0.8;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}
.myne-modal__modal-new-sale_bottom--right > button:active {
  transform: scale(0.97);
}

.myne-modal__modal-new-sale_bottom--right .active {
  background: #aa00ff;
  color: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

/* Content modal */

.myne-modal__modal-new-sale-content {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.myne-modal__modal-new-sale-content > header {
  display: flex;
  align-items: center;
  z-index: 1;
  width: 100%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
}

.myne-modal__modal-new-sale-content > header > div {
  flex: 1;
  display: flex;
  padding: 12px 24px;
  align-items: center;
  justify-content: center;
}

.myne-modal__modal-new-sale-content > header > div > button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 32px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 124px;
  background-color: #fff;
  cursor: pointer;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}
.myne-modal__modal-new-sale-content > header > div > button > svg {
  margin-right: 10px;
}
.myne-modal__modal-new-sale-content > header > div > button:hover {
  background-color: var(--background_hover);
}

.myne-modal__modal-new-sale-content > header > div:nth-child(2) {
  border-right: 1px solid #ebeff2;
  border-left: 1px solid #ebeff2;
}

.myne-modal__modal-new-sale-content > div {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

.myne-modal__modal-new-sale-content__area {
  flex: 1;
  width: 100%;
  display: flex;
  height: 100%;
  width: 296px;
  flex-direction: column;
  padding-bottom: 68px;
}

.myne-modal__modal-new-sale-content__area
  > .myne-modal__modal-new-sale-content__area-overflow {
  display: flex;
  height: 271px;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
}

.myne-modal__modal-new-sale-content__area:nth-child(2)
  > .myne-modal__modal-new-sale-content__area-overflow {
  border-right: 1px solid #ebeff2;
  border-left: 1px solid #ebeff2;
}

.myne-modal__modal-new-sale-content__area-user {
  margin: 24px;
  display: flex;
  align-items: center;
  padding: 0px 12px;
  width: 246px;
  height: 52px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 100px;
}

.myne-modal__modal-new-sale-content__area-user > img {
  width: 36px;
  height: 36px;
  margin: 0 !important;
  margin-right: 8px !important;
}

.myne-modal__modal-new-sale-content__area-user > p {
  margin: 0;
  max-width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 13.5px;
  margin-right: 10px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-modal__modal-new-sale-content__area-user > svg {
  margin-left: auto;
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.myne-modal__modal-new-sale-content__area-user > svg:hover {
  opacity: 0.7;
  transform: scale(1.1);
}

.myne-modal__modal-new-sale-content__area-product-service {
  margin: 8px 12px;
  display: flex;
  flex-direction: column;
  padding: 12px;
  width: 270px;
  max-width: 270px;
  min-width: 270px;
  position: relative;
  height: 94px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 16px;
}

.myne-modal__modal-new-sale-content__area-product-service > div {
  display: flex;
  align-items: center;
}
.myne-modal__modal-new-sale-content__area-product-service > div > img {
  width: 36px;
  height: 36px;
  margin-right: 12px !important;
  border-radius: 5px;
}
.myne-modal__modal-new-sale-content__area-product-service > div > p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.myne-modal__modal-new-sale-content__area-product-service > footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  margin-top: 8px;
  padding-top: 8px;
}
.myne-modal__modal-new-sale-content__area-product-service > footer > p {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
}
.myne-modal__modal-new-sale-content__area-product-service > footer > b {
  margin: 0;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 12px;
  text-align: right;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-new-sale-content__area-product-service > section {
  position: absolute;
  right: 12px;
  top: 12px;
  width: fit-content;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.myne-modal__modal-new-sale-content__area-product-service > section > svg {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.myne-modal__modal-new-sale-content__area-product-service
  > section
  > svg:hover {
  opacity: 0.7;
  transform: scale(1.1);
}

.myne-modal__modal-new-sale-content__area-product-service:nth-child(1) {
  margin-top: 24px;
}

.myne-modal__modal-new-sale-content__area-product-service:nth-last-child(1) {
  margin-bottom: 24px;
}

/*  */

.myne-modal__modal-new-sale-content__area-modal-search {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 364px;
  height: 355px;
  filter: drop-shadow(0px 0px 24px rgba(0, 0, 0, 0.12));
  background: #fff;
  z-index: 999;
  border-radius: 8px;
  padding: 26px;
  padding-bottom: 0;
  overflow-y: auto;
}

.myne-modal__modal-new-sale-content__area-modal-search__input {
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  padding-bottom: 8px;
}

.myne-modal__modal-new-sale-content__area-modal-search__input > input {
  width: 100%;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  background: none;
  outline: none;
  border: none;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.6);
}

.myne-modal__modal-new-sale-content__area-modal-search__input > svg {
  width: 24px;
  height: 24px;
  max-width: 24px;
  max-height: 24px;
  min-width: 24px;
  min-height: 24px;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user {
  display: flex;
  align-items: center;
  margin: 20px 0;
  padding: 4px 0;
  border-radius: 8px;
  cursor: pointer;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user:hover {
  background-color: var(--background_hover);
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user > p {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
  margin: 0;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user-photo {
  margin-right: 8px !important;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user-photo-img {
  width: 36px;
  max-width: 36px;
  max-height: 36px;
  min-width: 36px;
  min-height: 36px;
  background: #f5f5f5;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user-photo-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 36px;
  cursor: pointer;
  max-width: 36px;
  max-height: 36px;
  min-width: 36px;
  min-height: 36px;
  border: none;
  height: 36px;
  background: #aa00ff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 100px;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user
  .myne-item__cadastro {
  background-color: none;
  box-shadow: none;
  border: none;
  border-radius: 0;
  height: 0;
  max-height: 0;
  min-height: 0;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  margin-bottom: 0px;
  margin: 24px 0;
  width: 100%;
  justify-content: space-between;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user
  .myne-item__cadastro-left
  > section {
  padding: 5px 0;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user
  .myne-item__cadastro-left
  > section
  > p {
  font-size: 13.5px;
}
.myne-modal__modal-new-sale-content__area-modal-search__itens-user
  .myne-item__cadastro-right
  > section
  > p {
  font-size: 10px;
}
.myne-modal__modal-new-sale-content__area-modal-search__itens-user
  .myne-item__cadastro-right
  > section
  > h5 {
  font-size: 13.5px;
}
.myne-modal__modal-new-sale-content__area-modal-search__itens-user
  .myne-item__cadastro-left {
  margin-right: auto !important;
  flex: 1 !important;
}
.myne-modal__modal-new-sale-content__area-modal-search__itens-user
  .myne-item__cadastro-left
  > section
  > h5 {
  margin-top: 3px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
  max-width: 134px;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user
  .myne-item__cadastro-right {
  margin-left: auto !important;
}

.myne-modal__modal-new-sale-content__area-modal-search__itens-user
  .myne-item__cadastro-right
  > section {
  margin-left: 12px;
}

/* Fechamento */

.myne-modal__modal-new-sale-fechamento {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.myne-modal__modal-new-sale-fechamento:nth-child(1) {
  animation: animateflex 0.4s;
}
.myne-modal__modal-new-sale-fechamento:nth-child(2) {
  animation: animateflex 0.6s;
}
.myne-modal__modal-new-sale-fechamento:nth-child(3) {
  animation: animateflex 0.8s;
}
@keyframes animateflex {
  from {
    transform: translateY(-40px);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

.myne-modal__modal-new-sale-fechamento > p {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 24px;
}

.myne-modal__modal-new-sale-fechamento-parcelamento {
  border-left: 1px solid #ebeff2;
  border-right: 1px solid #ebeff2;
  overflow-y: auto;
}
.css-1a5icme.Mui-checked,
.css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked {
  color: #aa00ff !important;
}
.css-1a5icme,
.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  padding: 0 !important;
}

.myne-modal__modal-new-sale-fechamento-parcelas {
  overflow-y: auto;
}

/*  */
.myne-modal__modal-new-sale-fechamento-parcelamento-item {
  border-top: 1px dashed rgba(0, 0, 0, 0.06);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50.73px;
  max-height: 50.73px;
  min-height: 50.73px;
  cursor: pointer;
}
.myne-modal__modal-new-sale-fechamento-parcelamento-item:nth-last-child(1) {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.06);
}
.myne-modal__modal-new-sale-fechamento-parcelamento-item:hover {
  opacity: 0.9;
}
.myne-modal__modal-new-sale-fechamento-parcelamento-item > p:nth-child(1) {
  width: 24px;
  margin-right: 4px;
}
.myne-modal__modal-new-sale-fechamento-parcelamento-item > p:nth-child(2) {
  margin-right: auto;
}
.myne-modal__modal-new-sale-fechamento-parcelamento-item > div {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 10px;
  width: 70px;
  height: 30px;
  background: #dcffc2;
  border-radius: 4px;
  /* text */
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13.5px;
  color: #58cc02;
  /*  */
  margin-left: auto;
  margin-right: 29px;
}
.myne-modal__modal-new-sale-fechamento-parcelamento-item > p {
  font-family: "Poppins";
  margin: 0;
  font-weight: 400;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.87);
}

/*  */
.myne-modal__modal-new-sale-fechamento-parcelas
  > .myne-modal__modal-edit-cadastro-content__installments
  > div
  > p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
  margin-right: 23px;
  margin-left: 0;
  width: 60px;
  height: 18px;
  white-space: nowrap;
}
.myne-modal__modal-new-sale-fechamento-parcelas
  > .myne-modal__modal-edit-cadastro-content__installments
  > div
  > section
  > p,
.myne-modal__modal-new-sale-fechamento-parcelas
  > .myne-modal__modal-edit-cadastro-content__installments
  > div
  > label
  > p {
  margin: 0;
  padding: 0;
  margin-left: 8px;
  margin-right: 8px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
}
.myne-modal__modal-new-sale-fechamento-parcelas
  > .myne-modal__modal-edit-cadastro-content__installments
  > div {
  height: 42.73px;
  padding: initial;
}
.myne-modal__modal-new-sale-fechamento-parcelas
  > .myne-modal__modal-edit-cadastro-content__installments
  > div
  > div
  > p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13px;
  color: #aa00ff;
  height: 18px;
}
.myne-modal__modal-new-sale-fechamento-parcelas
  > .myne-modal__modal-edit-cadastro-content__installments
  > div
  > div
  > svg {
  width: 15px;
  height: 15px;
  margin-left: 4px;
}
.myne-modal__modal-edit-cadastro-content__installments > div > div {
  position: relative;
}
.myne-modal__modal-edit-cadastro-content__installments > div > div .ant-picker {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}
/*  */

.myne-modal__modal-new-sale-fechamento-valorvenda > section {
  display: flex;
  flex-direction: column;
}

.myne-modal__modal-new-sale-fechamento-valorvenda > section > div {
  margin-bottom: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.myne-modal__modal-new-sale-fechamento-valorvenda > section > div > p {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.87);
}
.myne-modal__modal-new-sale-fechamento-valorvenda > section > div > h4 {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
}
.myne-modal__modal-new-sale-fechamento-valorvenda > section > div > h5 {
  margin: 0;
  font-family: "Poppins";
  font-weight: 700;
  font-size: 16px;
  text-align: right;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-new-sale-fechamento-valorvenda > section > div > button {
  margin-right: 12px;
  margin-left: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 11px;
  width: 76px;
  height: 24px;
  background: rgba(255, 255, 255, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 54px;
  font-family: "Poppins";
  text-transform: uppercase;
  font-weight: 400;
  cursor: pointer;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-new-sale-fechamento-valorvenda
  > section
  > div
  > button:hover {
  background-color: var(--background_hover);
}

.myne-modal__modal-new-sale-fechamento-valorvenda > section > section {
  width: 100%;
  margin-bottom: 24px;
  border-top: 1px dashed rgba(0, 0, 0, 0.12);
}

/*  */

.myne-modal__modal-add-product-sale-item {
  width: 390px;
  height: 383px;
}

.myne-modal__modal-add-product-sale-item__container {
  padding: 24px;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.myne-modal__modal-add-product-sale-item__container-area-product {
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 342px;
  height: 174px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 16px;
}

.myne-modal__modal-add-product-sale-item__container-area-product > article {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.myne-modal__modal-add-product-sale-item__container-area-product > article > p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  margin: 0;
  line-height: 15px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-add-product-sale-item__container-area-product
  > article
  > p
  > span {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.6);
  margin-right: 10px;
}

.myne-modal__modal-add-product-sale-item__container-area-product
  > article
  > p
  > b {
  font-weight: 700;
}

.myne-modal__modal-add-product-sale-item__container-area-product > div {
  display: flex;
  align-items: center;
  padding: 16px 0;
  margin: 16px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.myne-modal__modal-add-product-sale-item__container-area-product > div > img {
  width: 40px;
  height: 40px;
  border-radius: 5px;
}
.myne-modal__modal-add-product-sale-item__container-area-product > div > svg {
  width: 32px;
  height: 32px;
  margin-right: 12px;
}
@media (max-width: 1340px) {
  .myne-modal__modal-add-product-sale-item__container-area-product > div > svg {
    display: none;
  }
}
.myne-modal__modal-add-product-sale-item__container-area-product > div > p {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  max-width: 142px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-add-product-sale-item__container-area-product
  > div
  > section {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.myne-modal__modal-add-product-sale-item__container-area-product
  > div
  > section
  > input {
  border: 1px solid rgba(0, 0, 0, 0.06);
  width: 47px;
  height: 47px;
  border-radius: 100%;
  text-align: center;
  outline: none;
  margin: 0 12px;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-add-product-sale-item__container-area-product
  > div
  > section
  > svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.myne-modal__modal-add-product-sale-item__container-area-product
  > div
  > section
  > svg:nth-child(1):active {
  transform: scale(0.88);
}

.myne-modal__modal-add-product-sale-item__container-area-product
  > div
  > section
  > svg:nth-child(3):active {
  transform: scale(1.1);
}

.myne-modal__modal-add-product-sale-item__container-area-product > section {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-add-product-sale-item__container-area-product > section > b {
  margin-left: 4px;
}

.myne-modal__modal-add-product-sale-item__container > button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 32px;
  width: 142px;
  cursor: pointer;
  height: 44px;
  background: #aa00ff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 124px;
  margin-top: auto;
  margin-left: auto;
  border: none;
  font-family: "Poppins";
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}

.myne-modal__modal-add-product-sale-item__container > button:active {
  transform: scale(0.95);
}
.myne-modal__modal-add-product-sale-item__container > button:hover {
  opacity: 0.8;
}

/* VIEW photo */

.myne-modal__view--photo {
  display: flex;
  position: relative;
}

.myne-modal__view--photo img {
  object-fit: contain;
  width: 360px;
  height: auto;
  max-height: 95%;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

@media (max-width: 500px) {
  .myne-modal__view--photo img {
    width: 296px;
  }
}

.myne-modal__view--photo svg {
  top: 12px;
  right: 12px;
  cursor: pointer;
  position: absolute;
}
