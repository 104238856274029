.myne-login {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: auto;
  z-index: 999;
  position: fixed;
  padding: 20px 10%;
  background-color: var(--background);
}

/**/
.myne-login .MuiInputBase-root,
.myne-login .MuiFormLabel-root {
  font-family: "Poppins", sans-serif;
}

.myne-login .MuiFormLabel-root {
  color: #000;
  opacity: 0.7;
  font-size: 15.3px;
  line-height: 0px;
}

.myne-login .MuiInput-underline:hover:not(.Mui-disabled):before {
  border-bottom: 2px solid rgba(119, 119, 119, 0.596);
}

.myne-login .MuiInput-underline:before {
  border-bottom: 1px solid #e3e3e3;
}
/**/

.login__titles {
  display: flex;
  flex-direction: column;
  width: 45%;
  height: 100%;
}

.login__titles > p {
  font-weight: 800;
  font-size: 48px;
  text-align: left;
  margin-left: 22px;
  width: 500px;
  margin-top: auto;
}

.login__titles > p > strong {
  color: var(--roxo-myne);
}

.login__titles > span {
  font-size: 18px;
  margin-top: 30px;
  width: 450px;
  margin-left: 22px;
  color: rgba(0, 0, 0, 0.64);
}

.login__titles > img {
  width: 340px;
  padding-top: 60px;
  margin-bottom: auto;
  margin-right: auto;
  margin-left: 60px;
  margin-top: auto;
}

/**/

.login__content,
.login__content-recuperar__senha {
  border-radius: 12px;
  box-shadow: var(--shadow-web);
  width: 456px;
  margin-left: 20px;
  padding: 40px 55px;
  display: flex;
  height: 435px;
  flex-direction: column;
  transition: all 0.5s ease;
  background: #fff;
}

.login__content > section {
  margin-bottom: 40px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.login__content > section span {
  margin-left: auto;
  margin-top: 18px;
  cursor: pointer;
  color: #000;
}

.login__content > section p {
  margin-top: 18px;
  color: rgb(245, 69, 69);
}

.input-form {
  width: 100%;
  align-items: center;
  display: flex;
  position: relative;
}

/**/
.form__group {
  position: relative;
  padding: 20px 0 0;
  width: 100%;
  height: 100%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  outline: 0;
  font-size: 15px;
  color: #000;
  padding-bottom: 4px;
  padding-top: 4px;
  z-index: 1;
  background: transparent;
  transition: border-color 0.2s;
}

.form__field:hover {
  border-bottom: 1px solid #8f8f8f;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 14px;
  cursor: text;
  top: 17px;
}

.form__field:placeholder-shown ~ .form__label-senha {
  font-size: 14px;
  cursor: text;
  top: 32px;
}

.form__label {
  position: absolute;
  top: 22px;
  display: block;
  transition: 0.2s;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.6);
}

.form__label-senha {
  position: absolute;
  top: 15px;
  display: block;
  transition: 0.2s;
  font-size: 13.5px;
  color: rgba(0, 0, 0, 0.6);
}

.form__field:focus,
.form__label:focus,
.form__label-senha:focus {
  padding-bottom: 6px;
  border-bottom: 2px solid var(--roxo-myne);
}

.form__field:focus + .input_label,
.form__field:valid + .input_label {
  color: #665856;
  -moz-transform: translateY(-25px);
  -ms-transform: translateY(-25px);
  -webkit-transform: translateY(-25px);
  transform: translateY(-25px);
}
.form__field:focus ~ .form__label,
.form__field:valid ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 13.5px;
}
.form__field:focus ~ .form__label-senha,
.form__field:valid ~ .form__label-senha {
  position: absolute;
  top: 17px;
  display: block;
  transition: 0.2s;
  font-size: 13.5px;
}

.form__field:focus ~ .form__label {
  color: var(--roxo-myne);
}

.form__field:focus
  ~ .form__label-senha
  .form__field:valid
  ~ .form__label-senha {
  position: absolute;
  top: 15px;
  display: block;
  transition: 0.2s;
  font-size: 13.5px;
}

.form__field:focus ~ .form__label-senha {
  color: var(--roxo-myne);
}

/* reset input */
.form__field:required,
.form__field:invalid {
  box-shadow: none;
}
/**/

.myne-login .MuiFormControl-root {
  width: 100%;
}

.input-form > svg {
  position: absolute;
  right: 0;
  z-index: 2;
  top: 31px;
  cursor: pointer;
}

.login__content a {
  text-decoration: none;
  color: var(--azul-myne);
  font-size: 16px;
  margin-top: 22px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
}

.login__content a:hover {
  text-decoration: underline;
}

/**/

.login__back {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}

.myne-login .MuiIconButton-root {
  margin-right: 8px;
  margin-left: -11px;
  cursor: pointer;
}

.login__back > p {
  font-weight: bold;
  font-size: 18px;
  color: rgba(0, 0, 0, 0.87);
}

.login__content-recuperar__senha > section {
  margin-top: auto;
}

.login__content-recuperar__senha > section > button {
  margin-top: 30px;
}

/* RESPONSIVE */

@media (max-width: 940px) {
  .myne-login {
    flex-direction: column;
    align-items: center;
  }
  .login__titles {
    width: 90%;
  }
  .login__titles > img {
    margin: 35px auto;
  }
  .login__content,
  .login__content-recuperar__senha {
    width: 480px;
    margin-left: 0px;
    padding: 30px 45px;
    display: flex;
    margin-top: 100px;
  }

  .login__titles > p {
    margin-left: 0px;
    width: 100%;
  }

  .login__titles > span {
    margin-left: 0px;
    width: 100%;
  }
}

@media (max-width: 541px) {
  .login__titles {
    width: 100%;
  }
  .login__titles > img {
    margin: 30px 0;
    width: 300px;
  }
  .login__titles > p {
    font-size: 42px;
  }
  .login__titles > span {
    font-size: 17px;
  }
  .login__content,
  .login__content-recuperar__senha {
    width: 100%;
    padding: 25px 22px;
    margin-top: 250px;
  }
}

@media (max-width: 444px) {
  .myne-login {
    padding: 50px 6%;
  }
}
