.myne-catalogo-header--filter {
  display: none;
  align-items: center;
  background-color: #fff;
  width: 100%;
  height: 48px;
  margin-top: 12px;
  overflow-x: auto;
}

.myne-catalogo-header--filter-btn {
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: #fff;
  padding: 0 16px;
  white-space: nowrap;
  font-weight: 500;
  font-size: 16px;
  text-transform: uppercase;
  color: var(--preto-60);
  border-bottom: 2px solid #fff;
}

.myne-catalogo-header--filter-btn-active {
  color: var(--preto-87);
  border-bottom: 3px solid var(--roxo-myne);
}
