.myne-catalogo {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 999;
  background: #fafafa;
  transition: none !important;
  animation: animatetop 0.4s;
}

@keyframes animatetop {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}

.myne-catalogo-digital--header {
  display: flex;
  width: 100%;
  height: 88px;
  background: #ffffff;
  position: fixed;
  top: 0;
  align-items: center;
  justify-content: space-between;
  padding: 0 244px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.myne-catalogo-digital--header > p {
  font-weight: 700;
  font-size: 24px;
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
}

.myne-catalogo-digital--header > button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 32px;
  background: rgba(255, 255, 255, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 124px;
  font-weight: 500;
  position: relative;
  font-size: 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
}

.myne-catalogo-digital--header > button > span {
  background: #fe2543;
  position: absolute;
  width: 8px;
  height: 8px;
  left: 49px;
  top: 10px;
  border-radius: 100%;
}

.myne-catalogo-digital--header > button:hover {
  background: #fafafa;
}

.myne-catalogo-digital--header > button:active {
  transform: scale(0.98);
}

.myne-catalogo-digital--header > button > svg {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

/* footer */
.myne-catalogo--footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 3;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  background: #f5f5f5;
}

.myne-catalogo--footer > p {
  margin: 0;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-catalogo--footer > p > strong {
  font-weight: 700;
  color: rgba(170, 0, 255, 1);
  text-decoration: underline;
  cursor: pointer;
  margin-left: 3px;
}

/*  */

.myne-catalogo-content {
  display: flex;
  padding: 24px 244px;
  padding-bottom: 0;
  overflow-y: auto;
  right: 0;
  left: 0;
  position: fixed;
  top: 88px;
  overflow-x: hidden;
  bottom: 50px;
  transition: none !important;
}

.myne-catalogo-content--filter {
  height: 90%;
  padding-bottom: 120px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  margin-bottom: 20px;
  z-index: -5;
  position: fixed;
  overflow-y: auto;
}

.myne-catalogo-content--filter::-webkit-scrollbar {
  display: none;
}

.myne-catalogo-content--filter::-webkit-scrollbar {
  display: none;
}

@media (max-height: 724px) {
  .myne-catalogo-content--filter {
    overflow-y: auto;
    height: 80%;
    padding-bottom: 70px;
  }
}

.myne-catalogo-content--filter__content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 16px 24px;
  width: 220px;
  height: 408px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 8px;
}

.myne-catalogo-content--filter__content--categories {
  overflow-y: auto;
  height: auto;
  flex-grow: 1;
  flex-shrink: 0;
  /* max-height: 408px; */
  max-height: 411px;
}

.myne-catalogo-content--filter__content-item {
  display: flex;
  flex-direction: column;
}

/* CATEGORIE  */

.myne-catalogo-content--filter__content-item-categories {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -8px;
}

.myne-catalogo-content--filter-btn {
  margin-bottom: 8px;
  margin-right: 8px;
  display: flex;
  padding: 5px 10px;
  justify-content: center;
  cursor: pointer;
  align-items: center;
  border-radius: 54px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  background: rgba(255, 255, 255, 0.87);
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 400;
}

.myne-catalogo-content--filter-btn:hover {
  background-color: var(--background_hover);
}

.myne-catalogo-content--filter-btn:active {
  transform: scale(0.97);
}

.myne-catalogo-content--filter-btn-active {
  border: 1px solid var(--roxo-myne);
  background: var(--roxo-myne-2);
  color: var(--roxo-myne);
}

/*  */

.myne-catalogo-content--filter__content-item > p {
  font-weight: 700;
  margin: 0;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
  margin-bottom: 24px;
}

.myne-catalogo-content--filter__content-item > label {
  display: flex;
  cursor: pointer;
  align-items: center;
  margin-top: 24px;
}

.myne-catalogo-content--filter__content-item > label > p {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  margin-left: 8px;
}

.myne-catalogo-content--filter__dica {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 16px;
  width: 220px;
  background: #f5f5f5;
  border-radius: 8px;
  margin-top: 12px;
}

.myne-catalogo-content--filter__dica > svg {
  width: 16px;
  height: 16px;
}

.myne-catalogo-content--filter__dica > p {
  margin: 0;
  margin-left: 8px;
  width: 164px;
  font-weight: 400;
  line-height: 18px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-catalogo-content--itens {
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  width: 100%;
  margin-left: 220px;
}

.myne-catalogo-content--search {
  margin-bottom: 32px;
  width: 342px;
}

.myne-catalogo-content--search svg {
  width: 20px;
  height: 20px;
  max-width: 20px;
  min-height: 20px;
  min-width: 20px;
  top: 19px !important;
  max-height: 20px;
}

.myne-catalogo-content--search .form__field:placeholder-shown ~ .form__label {
  font-size: 14px;
  cursor: text;
  top: 18px;
}
.myne-catalogo-content--search .form__field:focus ~ .form__label {
  color: var(--roxo-myne);
  font-size: 10px;
}

.myne-catalogo-content--search .form__field:focus + .input_label,
.myne-catalogo-content--search .form__field:valid + .input_label {
  color: #665856;
  -moz-transform: translateY(-20px);
  -ms-transform: translateY(-20px);
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
}
.myne-catalogo-content--search .form__field:focus ~ .form__label,
.myne-catalogo-content--search.form__field:valid ~ .form__label {
  top: 10px;
}

.myne-catalogo-content--products {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}

.myne-catalogo-product {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  isolation: isolate;
  width: 220px;
  cursor: pointer;
  background: #ffffff;
  height: 254px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  margin-right: 24px;
  margin-bottom: 24px;
}

.myne-catalogo-product__unavailable {
  filter: grayscale(100%);
  opacity: 0.7;
  cursor: not-allowed;
}

.myne-catalogo-product__unavailable button {
  cursor: not-allowed !important;
}

.myne-catalogo-product-image {
  width: 218px;
  height: 123px;
  min-height: 123px;
  position: relative;
  border-radius: 8px 8px 0px 0px;
}

.myne-catalogo-product-image > section {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  z-index: 1;
}

.myne-catalogo-product-image > section:hover {
  transform: scale(1.2, 1.2);
  opacity: 0.8;
}

.myne-catalogo-product-image > div {
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  height: 100%;
  background: rgba(0, 0, 0, 0.06);
  display: flex;
  justify-content: center;
  align-items: center;
}

.myne-catalogo-product-image img {
  width: 100%;
  object-fit: cover;
  height: 100%;
  border-radius: 8px 8px 0px 0px;
}

.myne-catalogo-product-image > span {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.myne-catalogo-product-image__slide {
  position: absolute;
  bottom: 12px;
  left: 12px;
  display: flex;
  gap: 8px;
  width: 100%;
  align-items: center;
}

.myne-modal__view--photo .myne-catalogo-product-image__slide {
  bottom: -10px;
  left: 0;
  justify-content: center;
}

.myne-catalogo-product-description > button {
  position: initial;
  bottom: initial;
  margin-top: auto;
  font-size: 14px;
  width: 85px;
  padding: 3px 10px;
  height: 44px;
  z-index: 1;
}

.btn-hover-add-product {
  position: absolute;
  bottom: -12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 10px;
  /* width: 66px; */
  background: #ffffff;
  right: 12px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 54px;

  font-family: "Poppins";
  font-weight: 400;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
}

.myne-catalogo-product:hover {
  border: 1px solid #aa00ff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.12);
}

.myne-catalogo-product:hover .btn-hover-add-product {
  background: #aa00ff;
  color: #fff;
}

.myne-catalogo-product-description {
  display: flex;
  flex-direction: column;
  padding: 12px;
  padding-top: 15px;
  width: 220px;
  height: 113px;
  height: 100%;
  min-width: 20px;
  max-width: 100%;
}

.myne-catalogo-product-description > p {
  font-family: "Poppins";
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.87);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 20px;
  max-width: 100%;
}

.myne-catalogo-product-description > h5 {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #aa00ff;
  margin: 4px 0;
}

.myne-catalogo-product-categories {
  display: flex;
  /* flex-wrap: wrap; */
  margin-top: 4px;
  overflow: hidden;
  white-space: nowrap;
  /* background-color: #aa00ff; */
}
.myne-catalogo-product-categories::-webkit-scrollbar {
  display: none;
}

.myne-catalogo-product-categories .marquee {
  display: flex;
  margin: 0;
  padding: 0;
  margin-top: -5px;
}

.myne-catalogo-product-categories h4 {
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.6);
  font-size: 8px;
  font-weight: 500;
  display: inline-block;
  text-transform: uppercase;
  margin-right: 2px;
}

.myne-catalogo-product-description > span {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-catalogo-content--products__not-itens {
  height: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.myne-catalogo-content--products__not-itens > p {
  margin: 0;
  width: 400px;
  height: 42px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
  margin-top: 20px;
}

.myne-modal__modal-add-product-sale-item-catalogo
  .myne-modal__modal-add-product-sale-item__container-area-product
  > div {
  padding-top: 0;
  margin-top: 0;
  border-top: initial;
}

.myne-modal__modal-add-product-sale-item-catalogo {
  width: 485px !important;
  height: 262px !important;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
}

.myne-modal__modal-add-product-sale-item-catalogo
  .myne-modal__modal-add-product-sale-item__container-area-product {
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 437px;
  height: 136px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 16px;
}

.myne-modal__modal-add-product-sale-item-catalogo
  .myne-modal__modal-add-product-sale-item__container-area-product
  > div
  > div
  img {
  max-width: 40px;
  min-width: 40px;
  min-height: 40px;
  max-height: 40px;
  border-radius: 5px;
  margin-right: 12px !important;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.myne-modal__modal-add-product-sale-item-catalogo
  .myne-modal__modal-add-product-sale-item__container
  > div {
  display: flex;
  align-items: center;
  margin-top: auto;
  width: 100%;
  align-items: flex-end;
}

.myne-modal__modal-add-product-sale-item-catalogo
  .myne-modal__modal-add-product-sale-item__container
  > div
  button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  white-space: nowrap;
  padding: 10px 32px;
  /* width: 214px; */
  height: 44px;
  margin-left: 12px;
  cursor: pointer;
  background: #aa00ff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 124px;
  font-weight: 500;
  font-size: 14px;
  color: #ffffff;
  margin-top: auto;
}

.myne-modal__modal-add-product-sale-item-catalogo
  .myne-modal__modal-add-product-sale-item__container
  > div
  a {
  margin-top: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 32px;
  font-weight: 500;
  cursor: pointer;
  font-size: 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  width: 211px;
  height: 44px;
  text-decoration: none;
  border-radius: 124px;
}

.myne-modal__modal-add-product-sale-item-catalogo
  .myne-modal__modal-add-product-sale-item__container
  > div
  a:hover,
.myne-modal__modal-add-product-sale-item-catalogo
  .myne-modal__modal-add-product-sale-item__container
  > div
  button:hover {
  opacity: 0.6;
}
.myne-modal__modal-add-product-sale-item-catalogo
  .myne-modal__modal-add-product-sale-item__container
  > div
  a:active,
.myne-modal__modal-add-product-sale-item-catalogo
  .myne-modal__modal-add-product-sale-item__container
  > div
  button:active {
  transform: scale(0.98);
}

.myne-modal__modal-add-product-sale-item-catalogo
  .myne-modal__modal-add-product-sale-item__container
  > div
  a
  > svg {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

/*  */

.myne-catalogo-digital--header__responsive-filters {
  display: none;
  margin-top: 0;
  align-items: center;
  width: 100%;
}

.myne-catalogo-digital--header__responsive-filters-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 31px;
  margin-right: 8px;
  background: rgba(255, 255, 255, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 54px;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.myne-catalogo-digital--header__responsive-filters-btn:nth-child(1) {
  width: 109px;
}
.myne-catalogo-digital--header__responsive-filters-btn:nth-child(2) {
  width: 104px;
}
.myne-catalogo-digital--header__responsive-filters-btn:nth-child(3),
.myne-catalogo-digital--header__responsive-filters-btn:nth-child(4) {
  width: 45px;
}

.myne-catalogo-digital--header__responsive-filters-btn-check {
  color: #aa00ff;
  border: 1px solid #aa00ff;
}

/*  */

.myne-catalogo-digital--header__responsive-texts {
  display: none;
  align-items: center;
  height: 36px;
  margin-bottom: 12px;
  width: 100%;
}

.myne-catalogo-content--search-responsive {
  width: 100%;
  margin-top: 12px;
}

.myne-catalogo-digital--header__responsive-texts > section {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  height: 36px;
}

.myne-catalogo-digital--header__responsive-texts > section > p {
  font-weight: 700;
  font-size: 24px;
  color: rgba(0, 0, 0, 0.87);
  max-width: 260px;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.myne-catalogo-digital--header__responsive-texts button {
  background: none;
  border: none;
  outline: none;
  padding: 0;
  width: 24px;
  position: relative;
  height: 24px;
}

.myne-catalogo-digital--header__responsive-texts button > svg {
  width: 24px;
  height: 24px;
}

.myne-catalogo-digital--header__responsive-texts button > span {
  background: #fe2543;
  position: absolute;
  width: 8px;
  height: 8px;
  left: 18px;
  top: 0;
  border-radius: 100%;
}

.myne-catalogo-digital--header__responsive-texts button > svg path {
  fill: rgba(0, 0, 0, 0.87);
}

.myne-catalogo-content--search-responsive .form__field:focus ~ .form__label {
  display: none;
}
.myne-catalogo-content--search-responsive
  .form__field:placeholder-shown
  ~ .form__label {
  display: none;
}

.myne-catalogo-product-description__responsive {
  display: none;
}

.myne-modal__modal-add-product-sale-item__container-area-product-itens-responsive {
  display: none;
}

.myne-modal_message-show {
  opacity: 1 !important;
  visibility: visible !important;
  right: 21px !important;
  z-index: 99999999999 !important;
}

.myne-modal_message-notshow {
  opacity: 0 !important;
  visibility: hidden !important;
  right: -100% !important;
  z-index: -1 !important;
}

@media (max-width: 1340px) {
  .myne-catalogo-digital--header > button {
    display: none;
  }
  .myne-catalogo-digital--header {
    flex-direction: column;
    width: 100%;
    height: 163px;
    align-items: flex-start;
    padding: 24px 0px;
    padding-bottom: 0;
  }
  .myne-catalogo-digital--header > p {
    display: none;
  }
  .form__field:focus ~ .form__label,
  .form__field:valid ~ .form__label {
    display: none !important;
  }
  .myne-catalogo-digital--header__responsive-filters {
    display: flex;
    padding: 0 16px;
  }
  .myne-catalogo-content--search-responsive {
    display: flex !important;
  }
  .myne-catalogo-header--filter {
    display: flex !important;
  }
  .myne-catalogo-digital--header__responsive-texts {
    display: flex;
    padding: 0 16px;
  }
  .myne-catalogo-content {
    display: flex;
    padding: 24px 16px;
    padding-bottom: 0;
    overflow-y: auto;
    right: 0;
    left: 0;
    position: fixed;
    top: 163px;
    overflow-x: hidden;
    bottom: 0;
    transition: none !important;
  }
  .myne-catalogo--footer {
    display: none;
  }
  .myne-catalogo-content--filter {
    display: none;
  }
  .myne-catalogo-content--itens {
    padding: 0;
    width: 100%;
    margin-left: 0;
  }
  .myne-catalogo-content--search {
    display: none;
  }
  .myne-catalogo-product {
    width: 328px;
    min-height: 352px;
    height: auto;
    margin: 0 auto !important;
    position: relative;
    margin-bottom: 12px !important;
  }

  .myne-catalogo-product-image {
    width: 328px;
    height: 183px;
  }
  .myne-catalogo-product-description > button {
    position: absolute;
    left: 12px;
    bottom: 16px;
  }
  .myne-catalogo-product-description {
    display: flex;
    flex-direction: column;
    padding: 12px 12px;
    padding-bottom: 16px;
    width: 100%;
    height: 100%;
  }
  .btn-hover-add-product-second {
    display: flex !important;
    justify-content: center;
    align-items: center;
    padding: 10px 32px;
    width: 134px !important;
    background: #aa00ff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
    border-radius: 124px;
    font-weight: 500;
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    margin-left: auto;
    color: #ffffff;
  }
  .myne-catalogo-product-image button {
    display: none;
  }
  .myne-catalogo-product-description > p {
    display: none;
  }
  .myne-catalogo-product-description > h5 {
    display: none;
  }
  .myne-catalogo-product-description > span {
    font-weight: 400;
    font-size: 16px;
    margin-top: 14px;
    margin-bottom: 50px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
  }
  .myne-catalogo-product-description__responsive {
    display: flex;
    justify-content: space-between;
    /* align-items: flex-end; */
    width: 100%;
    min-width: 20px;
  }
  .myne-catalogo-product-description__responsive > p {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 20px;
    max-width: 100%;
  }
  .myne-catalogo-product-description__responsive > h5 {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    position: relative;
    top: -4px;
    color: #aa00ff;
    white-space: nowrap;
  }
  .myne-catalogo-content--products__not-itens {
    width: 328px;
  }
  .myne-catalogo-content--products__not-itens > p {
    width: 228px;
    height: 63px;
  }
  .myne-catalogo-content--filter__dica-responsive {
    width: 100%;
    height: 104px;
    margin: 0;
  }
  .myne-catalogo-content--filter__dica-responsive > svg {
    width: 20px;
    height: 20px;
  }
  .myne-catalogo-content--filter__dica-responsive > p {
    width: 268px;
    height: 72px;
    font-size: 12px;
  }
  .myne-modal__modal-add-product-sale-item-catalogo {
    width: 262px !important;
    height: 370px !important;
    top: 55px !important;
  }
  .myne-modal__modal-add-product-sale-item-catalogo
    .myne-modal__modal-add-product-sale-item__container-area-product {
    width: 100%;
    height: auto;
  }
  .myne-modal__modal-add-product-sale-item-catalogo
    .myne-modal__modal-add-product-sale-item__container
    > div {
    flex-direction: column-reverse;
    align-items: center;
  }
  .myne-modal__modal-add-product-sale-item-catalogo
    .myne-modal__modal-add-product-sale-item__container
    > div
    button {
    margin: 0;
    margin-bottom: 12px;
  }
  .myne-modal__modal-add-product-sale-item-catalogo
    .myne-modal__modal-add-product-sale-item__container-area-product
    > div {
    flex-direction: column;
    align-items: flex-start;
  }
  .myne-modal__modal-add-product-sale-item__container-area-product
    > div
    > section {
    margin: 0 auto;
    margin-top: 12px;
  }
  .myne-modal__modal-add-product-sale-item-catalogo
    .myne-modal__modal-add-product-sale-item__container-area-product
    > div
    > div
    > img {
    display: none;
  }
  .myne-modal__modal-add-product-sale-item__container-area-product-itens-responsive {
    display: flex;
    align-items: center;
  }
  .myne-modal__modal-add-product-sale-item__container-area-product-itens-responsive
    > svg {
    max-width: 32px;
    min-width: 32px;
    min-height: 32px;
    max-height: 32px;
    margin-right: 12px !important;
  }
  .myne-modal__modal-add-product-sale-item__container-area-product-itens-responsive
    > p {
    margin: 0;
    font-family: "Poppins";
    font-weight: 400;
    font-size: 14px;
    max-width: 130px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(0, 0, 0, 0.87);
  }
  .myne-modal__modal-add-product-sale-item__container-area-product-itens-responsive
    > img {
    max-width: 40px;
    min-width: 40px;
    min-height: 40px;
    max-height: 40px;
    margin-right: 12px !important;
    border-radius: 5px;
    object-fit: cover;
  }
  .myne-modal__modal-add-product-sale-item__container-area-product > div > p {
    display: none;
  }
  .myne-modal_message-show {
    left: 42px !important;
    right: 42px !important;
    bottom: 50px !important;
    top: initial !important;
  }
  .myne-modal_message-notshow {
    bottom: 50px !important;
    top: initial !important;
    left: 100% !important;
    right: -100% !important;
  }
  /* LIST */
  .myne-catalogo-product__list {
    height: 200px !important;
    min-height: 200px !important;
  }
  .myne-catalogo-product__list .myne-catalogo-product-image {
    width: 110px !important;
    max-width: 110px !important;
    min-width: 110px !important;
  }
  .myne-catalogo-product__list .myne-catalogo-product-image img {
    object-fit: contain;
  }

  /*  */

  .myne-catalogo-product-image .myne-catalogo-product-image__slide {
    right: 12px;
    justify-content: right;
    left: initial;
  }
   .myne-catalogo-product-image__slide > div {
    width: 6px !important;
    height: 6px !important;
   }
}
