.myne-item__cadastro {
  background-color: #fff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 8px;
  height: 90px;
  max-height: 90px;
  min-height: 90px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px;
  margin-bottom: 12px;
}

.myne-item__cadastro:hover {
  background-color: rgb(250, 250, 250);
  /* background-color: rgba(250, 249, 249, 0.87); */
  border: 1px solid rgba(0, 0, 0, 0.36);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
}

.myne-item__cadastro-left {
  display: flex;
  align-items: center;
}

.myne-item__cadastro-left > img {
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
  min-width: 40px;
  min-height: 40px;
  margin-right: 16px;
  object-fit: cover;
  border-radius: 5px;
}

.myne-item__cadastro-left > section {
  display: flex;
  padding: 0;
  margin: 0;
  flex-direction: column;
}

.myne-item__cadastro-left > section > p {
  margin: 0;
  font-weight: 600;
  font-size: 15px;
  opacity: 0.87;
  color: rgba(0, 0, 0, 0.87);
}

.myne-item__cadastro-left > section > h5 {
  margin: 0;
  margin-top: 2px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
  opacity: 0.87;
  font-weight: 400;
  white-space: nowrap;
  order: 1;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 500px;
}

.myne-item__cadastro-right {
  display: flex;
  align-items: center;
}

.myne-item__cadastro-right > section {
  display: flex;
  flex-direction: column;
  margin-left: 32px;
}

.myne-item__cadastro-right > section > p {
  margin: 0;
  font-size: 11px;
  color: rgba(0, 0, 0, 0.6);
  opacity: 0.87;
  white-space: nowrap;
  text-align: right;
}

.myne-item__cadastro-right > section > h5 {
  margin: 0;
  margin-top: 4px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 15px;
  font-weight: 600;
  opacity: 0.87;
  text-align: right;
  white-space: nowrap;
}

/* MODAL EDIT */

.myne-modal__modal-edit-cadastro {
  position: absolute;
  width: 611px;
  height: 479px;
  background: #ffffff;
  box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  display: flex;
  transition: all 0.3s ease;
  flex-direction: column;
}

.myne-modal__modal-edit-header {
  width: 100%;
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #ebeff2;
}

.myne-modal__modal-edit-header__left {
  display: flex;
  align-items: center;
}

.myne-modal__modal-edit-header__left > h5 {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  margin: auto 0;
  margin-left: 4px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-edit-header__left > svg {
  margin-right: 15px;
}

.myne-modal__modal-edit-header__left > p {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  text-transform: uppercase;
  margin: 0;
}

.myne-modal__modal-edit-header__icons {
  display: flex;
  align-items: center;
}

.myne-modal__modal-edit-header__icons > svg {
  margin-left: 24px;
  width: 24px;
  cursor: pointer;
  height: 24px;
  transition: all 0.3s ease;
}

.myne-modal__modal-edit-header__icons > svg path {
  fill: rgba(0, 0, 0, 0.6);
  fill-opacity: 1 !important;
  opacity: 1 !important;
}

.myne-modal__modal-edit-header__icons > svg:hover {
  opacity: 0.7;
}

.myne-modal__modal-edit-cadastro-content {
  display: flex;
  flex: 1;
  height: 1px;
}

.myne-modal__modal-edit-cadastro-content__inputs {
  display: flex;
  flex-direction: column;
  padding: 24px;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 0.6;
  height: 100%;
}

.myne-modal__modal-edit-cadastro-content__inputs > form {
  margin-bottom: 14px;
}

.myne-modal__modal-edit-cadastro-content__right-subimit {
  display: flex;
  flex-direction: column;
  padding: 24px;
  flex: 0.4;
  border-left: 1px solid #ebeff2;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__photo > img {
  width: 100%;
  object-fit: cover;
  height: 100px;
  border-radius: 8px;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__photo--null {
  width: 100%;
  object-fit: cover;
  height: 100px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.06);
  border: 1px dashed rgba(0, 0, 0, 0.06);
}

.myne-modal__modal-edit-cadastro-content__right-subimit__stock-min {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__stock-min > p {
  color: rgba(0, 0, 0, 0.87);
  font-size: 14px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 10px;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__stock-min > div {
  display: flex;
  flex-wrap: wrap;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__stock-min
  > div
  > button {
  border: hidden;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 12px;
  font-size: 14px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 54px;
  /* margin: 4px; */
  margin-right: 7px;
  margin-bottom: 7px;
  cursor: pointer;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__stock-min
  > div
  > button:hover {
  border: 1px solid rgba(0, 0, 0, 0.205);
}

.myne-modal__modal-edit-cadastro-content__right-subimit__photo {
  display: flex;
  flex-direction: column;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__photo > div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__photo > div > p {
  color: rgba(0, 0, 0, 0.6);
  font-size: 13px;
  margin: 0;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__photo > div > svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__photo
  > div
  > svg:hover {
  opacity: 0.6;
}

.myne-modal__modal-edit-cadastro-content__right-subimit__photo
  > div
  > svg
  path {
  fill: rgba(0, 0, 0, 0.6);
  fill-opacity: 1 !important;
}

.myne-modal__modal-edit-cadastro-content__right-subimit > button {
  border: none;
  outline: none;
  display: flex;
  position: absolute;
  bottom: 24px;
  right: 24px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 13px 18px 13px 18px;
  cursor: pointer;
  background: #aa00ff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12),
    0px 2px 4px rgba(0, 0, 0, 0.14);
  border-radius: 100px;
  width: fit-content;
  transition: all 0.3s ease;
}

.myne-modal__modal-edit-cadastro-content__right-subimit > button:active {
  transform: scale(0.97);
}

.myne-modal__modal-edit-cadastro-content__right-subimit > button:hover {
  opacity: 0.8;
}

.myne-modal__modal-edit-cadastro-content__right-subimit > button > p {
  color: #ffffff;
  font-size: 13.5px;
  font-weight: 400;
  margin: 0;
  text-transform: uppercase;
  letter-spacing: 1.25px;
}

.myne-modal__modal-edit-cadastro-content__right-subimit > button > svg {
  width: 16px;
  height: 16px;
  margin-right: 14px;
}

.myne-modal__modal-edit-cadastro-content__right-subimit > button > svg path {
  fill: #fff;
}

/* MESSAGE */

.myne-modal_message {
  display: flex;
  align-items: center;
  padding: 16px;
  position: fixed;
  white-space: nowrap;
  height: 56px;
  top: 75px;
  transition: all 0.7s ease;
  right: 21px;
  z-index: 999;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  font-weight: 600;
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-modal_message > svg {
  margin-right: 10px;
  width: 24px;
  height: 24px;
  max-width: 24px;
  max-height: 24px;
  min-width: 24px;
  min-height: 24px;
}

.myne-modal_message-sucess {
  background: #dcffc2;
}

.myne-modal_message-erro {
  background: #ffdbe0;
}

/* myne-modal__modal-edit-cadastro-content__select */

.myne-modal__modal-edit-cadastro-content__select-p {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-modal__modal-edit-cadastro-content__select {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
}

.myne-modal__modal-edit-cadastro-content__select > button {
  padding: 4px 10px;
  margin-right: 9px;
  transition: all 0.2s ease;
  border-radius: 35px;
  display: flex;
  align-items: center;
  z-index: 1;
  outline: none;
  cursor: pointer;
  background: rgb(255, 255, 255, 0);
  border: none;
  transform: scale(1);
  font-size: 13px;
  white-space: nowrap;
  color: rgba(0, 0, 0, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.06);
}

.myne-modal__modal-edit-cadastro-content__select > button:hover {
  border: 1px solid rgba(0, 0, 0, 0.219);
}

.myne-modal__modal-edit-cadastro-content__select > button > svg {
  margin-right: 10px;
  color: black;
  opacity: 0.87;
}

.myne-modal__modal-edit-cadastro-content__select > button > svg path {
  fill: rgba(0, 0, 0, 0.87);
}
