/* *POPPINS* */
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  transition: all 0.2s ease !important;
}

code {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
}

html[lang="pt-BR"] {
  margin-top: 40px;
  overflow: hidden;
}

img:not([src]):not([srcset]) {
  visibility: hidden;
}

html {
  /* COLORS STYLES */

  --roxo-myne: #aa00ff;
  --antd-wave-shadow-color: #aa00ff !important;
  --roxo-myne-36: rgba(170, 0, 255, 0.36);
  --roxo-myne-2: #faefff;

  --azul-myne: #85d1f5;
  --border-myne: #ebeff2;

  /* EFFECT STYLES */

  --shadow-button: 0px 4px 8px rgba(0, 0, 0, 0.12);
  --shadow-web: 0 0 24px 0 rgba(0, 0, 0, 0.12);

  /* COLORS */

  --preto-6: rgba(0, 0, 0, 0.06);
  --branco-87: rgba(255, 255, 255, 0.87);
  --preto-87: rgba(0, 0, 0, 0.87);
  --preto-60: rgba(0, 0, 0, 0.60);

  /* BACKGROUNDS */

  --background: #fcfcfc;
  --background-second: rgba(0, 0, 0, 0.6);
  --background_hover: rgb(249, 249, 252);
}

.myne-modal--animation {
  transition: all 0.4s ease;
  animation: initopacity 0.8s ease;
}
.myne-modal--animation-scale {
  transition: all 0.2s ease;
  animation: initscale 0.4s ease;
}
.myne-modal--animation-top {
  transition: all 0.3s ease;
  animation: initop 0.45s ease;
}
.myne-modal--animation-left {
  transition: all 0.4s ease;
  animation: inileft 0.5s ease;
}
.myne-modal--animation-right {
  transition: all 0.4s ease;
  animation: iniright 0.5s ease;
}

@keyframes initscale {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes initopacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes inileft {
  0% {
    opacity: 0;
    transform: translateX(8%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}
@keyframes iniright {
  0% {
    opacity: 0;
    transform: translateX(-8%);
  }
  100% {
    opacity: 1;
    transform: translateX(0%);
  }
}

@keyframes initop {
  0% {
    opacity: 0;
    transform: translateY(-15%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
@keyframes initopmore {
  0% {
    opacity: 0;
    transform: translateY(-25%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}

strong {
  color: var(--roxo-myne);
}

#google_translate_element {
  position: fixed;
  bottom: -17px;
  right: 5px;
  z-index: 999;
}

#google_translate_element select {
  width: 200px;
  display: flex;
  align-items: center;
  border: none;
  outline: none;
  font-family: "Poppins", sans-serif;
  cursor: pointer;
  padding: 0px 10px;
  height: 35px;
  border-radius: 10px;
  background-color: #f1f1f1;
}

#google_translate_element select option {
  min-height: 50px;
  padding: 20px 0;
}

.goog-te-banner-frame {
  display: none;
}

#root {
  position: relative;
  margin-top: -40px;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}
::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.06);
}
::-webkit-scrollbar-thumb {
  background-color: var(--roxo-myne-36);
  border-radius: 12px;
}

@media (max-width: 500px) {
  ::-webkit-scrollbar {
    display: none;
  }
}

/* switch */

.switch {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 11px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch > p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
  margin-right: 8px;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.38);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  background: #fff;
  z-index: 1;
  bottom: -2px;
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.141));
  filter: drop-shadow(0px 2px 1px rgba(0, 0, 0, 0.122));
  -webkit-transition: 0.4s;
  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.2));
  transition: 0.4s;
}

input:checked + .slider {
  background: #dcffc2;
}

input:checked + .slider:before {
  background: #58cc02;
}

input:focus + .slider {
  box-shadow: 0 0 1px #dcffc2;
}

input:checked + .slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* RECIBO */

.myne-recibo {
  width: 100%;
  height: 100%;
  background: #ffffff;
  position: fixed;
  z-index: 99999999;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
}

.myne-recibo > svg {
  position: fixed;
  top: 15px;
  left: 15px;
  width: 28px;
  height: 28px;
  cursor: pointer;
}
.myne-recibo > svg:hover {
  transform: scale(1.1);
  opacity: 0.7;
}
.myne-recibo-content__btns {
  position: fixed;
  right: 15px;
  top: 15px;
}

.myne-recibo-content__btns > div {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  font-family: "Poppins";
  font-weight: 400;
  cursor: pointer;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.myne-recibo-content__btns > div:hover {
  opacity: 0.7;
}
.myne-recibo-content__btns > div:active {
  transform: scale(0.98);
}
.myne-recibo-content__btns > div > b {
  margin-left: 4px;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.87);
}
.myne-recibo-content__btns > div > svg {
  width: 18px;
  height: 18px;
  margin-right: 5px;
}

.myne-recibo-content {
  width: 595px;
  /* height: 842px; */
  margin: auto;
  display: flex;
  flex-direction: column;
  background: #ffffff;
}

.myne-recibo-content > footer {
  width: 595px;
  height: 57px;
  max-height: 57px;
  min-height: 57px;
  background: rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 57px;
  margin-top: auto;
}

.myne-recibo-content > footer > p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-recibo-content > footer > p > b {
  font-weight: 700;
}

.myne-recibo-content > footer > img {
  width: 106px;
  height: 21px;
}

/* Main recibo */

.myne-recibo-content__main {
  padding: 57px;
  display: flex;
  flex-direction: column;
}

.myne-recibo-content__main > header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.myne-recibo-content__main > header > div {
  display: flex;
  align-items: center;
}

.myne-recibo-content__main > header > div > svg {
  width: 24px;
  height: 24px;
  margin-right: 12px;
}

.myne-recibo-content__main > header > div > p {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-recibo-content__main > header > div > p > b {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 700;
}

.myne-recibo-content__main > header > p {
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-recibo-content__main--info {
  display: flex;
  width: 100%;
  margin: 32px 0;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  gap: 20px;
  width: 481px;
  height: 82px;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 16px;
}

.myne-recibo-content__main--info > section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
  width: 134px;
  height: 42px;
}

.myne-recibo-content__main--info > section > p {
  width: 134px;
  height: 16px;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
}

.myne-recibo-content__main--info > section > h5 {
  width: 68px;
  height: 18px;
  font-family: "Poppins";
  font-weight: 600;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
  margin: 0;
  white-space: nowrap;
}

.myne-recibo-content__main--itens {
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.myne-recibo-content__main--table {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 16px;
}

.myne-recibo-content__main--table-titles {
  width: 100%;
  display: flex;
  align-items: center;
  padding-bottom: 8px;
  width: 481px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.36);
}

.myne-recibo-content__main--table-titles > p {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 12px;
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-recibo-content__main--table-titles > h5 {
  margin: 0;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-recibo-content__main--table-itens {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.myne-recibo-content__main--table-itens-item {
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 8px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
}

.myne-recibo-content__main--table-itens-item > p {
  height: 21px;
  display: flex;
  align-items: center;
  font-family: "Poppins";
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-recibo-content__main--table-itens-item > h5 {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 12px;
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  padding-top: 2px;
  opacity: 0.87;
}

.myne-recibo-content__main--table-itens-item > b {
  font-family: "Poppins";
  font-weight: 700;
  font-size: 14px;
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-recibo-content__main--info_parcelamento {
  display: flex;
  flex-direction: column;
}

.myne-recibo-content__main--info_parcelamento > p {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.6);
  opacity: 0.87;
}
