.myne-carrinho {
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.3s ease;
}

.myne-carrinho-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.myne-carrinho-content__header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.myne-carrinho-content__link {
  display: flex;
  align-items: center;
}

.myne-carrinho-content__link > a,
.myne-carrinho-content__link > p {
  font-weight: 700;
  font-size: 16px;
  margin: 0;
  color: rgba(0, 0, 0, 0.87);
}

.myne-carrinho-content__link > a {
  text-decoration-line: underline;
}

.myne-carrinho-content__link > p {
  margin-left: 4px;
}

.myne-carrinho-content__header > button {
  width: 219px;
  height: 44px;
}

.myne-carrinho button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 32px;
  background: rgba(255, 255, 255, 0.87);
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 124px;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  color: rgba(0, 0, 0, 0.87);
}

.myne-carrinho button > svg {
  margin-right: 10px;
}

.myne-carrinho button:hover {
  background: rgba(255, 255, 255, 0);
}

.myne-carrinho button:active {
  transform: scale(0.98);
}

.myne-carrinho-content__list {
  display: flex;
  flex-direction: column;
}

.myne-carrinho-content__list-titles {
  display: flex;
  margin-bottom: 12px;
  margin-top: 32px;
  align-items: center;
}

.myne-carrinho-content__list-titles > span {
  font-weight: 400;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.6);
  opacity: 0.87;
}

.myne-carrinho-content__list-titles > span:nth-child(1) {
  margin-left: 16px;
}

.myne-carrinho-content__list-titles > span:nth-child(2) {
  margin-left: 360px;
  width: 124px;
}

.myne-carrinho-content__list-titles > span:nth-child(3) {
  margin-left: 32px;
  width: 119px;
}

.myne-carrinho-content__list-titles > span:nth-child(4) {
  margin-left: 32px;
  width: 124px;
}

.myne-carrinho-content__list-titles > p {
  margin-bottom: 24px;
  margin-top: 32px;
  font-weight: 400;
  font-size: 16px;
  text-align: right;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
  margin-left: auto;
}

.myne-carrinho-content__list-titles > p > b {
  font-weight: 600;
}

/* Cart */

.myne-catalogo-product__cart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 16px;
  width: 100%;
  height: 92px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  margin-bottom: 12px;
}

.myne-catalogo-product__cart:nth-last-child(1) {
  margin-bottom: 0;
}

.myne-catalogo-product__cart > div {
  display: flex;
  align-items: center;
  margin-right: 24px;
  width: 121px;
}

.myne-catalogo-product__cart > div img {
  object-fit: cover;
  width: 52px;
  max-width: 52px;
  min-width: 52px;
  height: 52px;
  border-radius: 8px;
  margin-right: 16px;
}

.myne-catalogo-product__cart > div svg {
  width: 32px;
  max-width: 32px;
  min-width: 32px;
  height: 32px;
  margin-right: 16px;
}

.myne-catalogo-product__cart > div p {
  margin: 0;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
  white-space: nowrap;
}

.myne-catalogo-product__cart > h5 {
  width: 124px;
  font-weight: 400;
  margin: 0;
  font-size: 16px;
  margin-left: 255px;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-catalogo-product__cart > h4 {
  width: 111px;
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  text-align: right;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
}

.myne-catalogo-product__cart > svg {
  margin-left: auto;
  cursor: pointer;
}
.myne-catalogo-product__cart > svg:hover {
  opacity: 0.6;
}
.myne-catalogo-product__cart > svg:active {
  transform: scale(0.97);
}

/*  */

.myne-catalogo-product__cart > section {
  display: flex;
  align-items: center;
  margin-left: 32px;
}

.myne-catalogo-product__cart > section > input {
  border: 1px solid rgba(0, 0, 0, 0.06);
  width: 47px;
  height: 47px;
  border-radius: 100%;
  text-align: center;
  outline: none;
  margin: 0 12px;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-catalogo-product__cart > section > svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.myne-catalogo-product__cart > section > svg:nth-child(1):active {
  transform: scale(0.88);
}

.myne-catalogo-product__cart > section > svg:nth-child(3):active {
  transform: scale(1.1);
}

/* bottom */

.myne-carrinho-content__bottom {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  padding-top: 32px;
  display: flex;
  align-items: center;
  padding-bottom: 80px;
}

.myne-carrinho-content__bottom .myne-catalogo-content--filter__dica {
  margin: 0;
  margin-right: auto;
  width: 343px;
  height: 92px;
  padding: 10px 16px;
}

.myne-carrinho-content__bottom .myne-catalogo-content--filter__dica > p {
  width: 287px;
  height: 72px;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.87);
}

.myne-carrinho-content__bottom > button:nth-child(2) {
  width: 276px;
  margin-right: 12px;
}

.myne-carrinho-content__bottom > button:nth-child(3) {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 32px; /* width: 214px; */
  height: 44px;
  background: #aa00ff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 124px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  color: #ffffff;
}

.myne-carrinho-content__not-itens {
  width: 100%;
  height: 229px;
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.myne-carrinho-content__not-itens > svg {
  width: 96px;
  height: 96px;
}

.myne-carrinho-content__not-itens > p {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: rgba(0, 0, 0, 0.87);
  opacity: 0.87;
  margin-top: 20px;
  margin-bottom: 32px;
}

.myne-carrinho-content__not-itens > button {
  /* width: 152px; */
}

.myne-catalogo-digital--header__responsive-cart {
  display: none;
}

.myne-carrinho-content__list-responsive {
  display: none;
}

/* item cart responsive */
.myne-catalogo-product__cart-responsive {
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
  height: 141px;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.06);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  margin-top: 12px;
  transition: all 0.3s ease;
  animation: animatescale 0.4s ease;
}

@keyframes animatescale {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.myne-catalogo-product__cart-responsive > div,
.myne-catalogo-product__cart-responsive > section {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.myne-catalogo-product__cart-responsive > div {
  padding-bottom: 16px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 16px;
}
.myne-catalogo-product__cart-responsive:nth-child(1) {
  margin-top: 0;
}
.myne-catalogo-product__cart-responsive-image {
  display: flex;
  align-items: center;
  width: 100%;
  min-width: 20px;
}
.myne-catalogo-product__cart-responsive-image img {
  object-fit: cover;
  width: 52px;
  height: 52px;
  border-radius: 8px;
  margin-right: 12px;
}
.myne-catalogo-product__cart-responsive-image svg {
  width: 32px;
  height: 32px;
  margin-right: 12px;
}
.myne-catalogo-product__cart-responsive-image p {
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 20px;
  max-width: 100%;
}
.myne-catalogo-product__cart-responsive-image-add-remove {
  display: flex;
  align-items: center;
}
.myne-catalogo-product__cart-responsive-image-add-remove > input {
  border: 1px solid rgba(0, 0, 0, 0.06);
  width: 47px;
  height: 47px;
  border-radius: 100%;
  text-align: center;
  outline: none;
  margin: 0 12px;
  font-weight: 400;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.87);
}
.myne-catalogo-product__cart-responsive-image-add-remove > svg {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
.myne-catalogo-product__cart-responsive-image-add-remove
  > svg:nth-child(1):active {
  transform: scale(0.88);
}
.myne-catalogo-product__cart-responsive-image-add-remove
  > svg:nth-child(3):active {
  transform: scale(1.1);
}
.myne-catalogo-product__cart-responsive > section > p {
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
}
.myne-catalogo-product__cart-responsive > section > p > b {
  margin-left: 4px;
}

/* Responsive */

@media (max-width: 1340px) {
  .myne-catalogo-digital--header_responsive_cart {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 16px;
    width: 100%;
    height: 84px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  }
  .myne-catalogo-digital--header__responsive-cart {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
  }
  .myne-catalogo-digital--header__responsive-cart > section {
    display: flex;
    align-items: center;
  }
  .myne-catalogo-digital--header__responsive-cart > section > svg {
    margin-right: 10px;
    margin-left: 5px;
  }
  .myne-catalogo-digital--header__responsive-cart > section > p {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.87);
    margin: 0;
  }
  .myne-catalogo-digital--header__responsive-cart > p {
    margin: 0;
    font-weight: 700;
    font-size: 24px;
    line-height: 36px;
    max-width: 220px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(0, 0, 0, 0.87);
  }
  .myne-catalogo-content-responsive-cart {
    top: 84px;
  }
  /*  */
  .myne-carrinho-content__header {
    display: none;
  }
  .myne-carrinho-content__list {
    display: none;
  }
  .myne-carrinho-content__bottom {
    border: none;
    padding: 0;
    width: 100%;
    background: #fafafa;
    margin-top: auto;
    flex-direction: column-reverse;
    padding-bottom: 10px;
    padding-top: 5px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    position: fixed;
  }
  .myne-carrinho-content__bottom > button {
    width: 328px !important;
    margin: 0;
    margin-bottom: 12px;
  }
  .myne-carrinho-content__bottom .myne-catalogo-content--filter__dica {
    display: none;
  }
  .myne-carrinho-content__not-itens {
    height: 100%;
  }
  .myne-carrinho-content__not-itens > button {
    margin-top: auto;
    margin-bottom: 37px;
    width: 100% !important;
  }
  .myne-carrinho-content__list-responsive {
    display: flex;
    flex-direction: column;
  }
  .myne-carrinho-content__list-titles-responsive {
    margin-top: 32px;
    margin-bottom: 200px;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.06);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .myne-carrinho-content__list-titles-responsive > button {
    padding: 10px 16px;
    width: 132px;
    height: 44px;
    font-size: 14px;
    text-transform: uppercase;
  }
  .myne-carrinho-content__list-titles-responsive > p {
    font-weight: 400;
    font-size: 16px;
    text-align: right;
    color: rgba(0, 0, 0, 0.87);
    opacity: 0.87;
  }
}
